require("@rails/ujs").start()
require("turbolinks").start()
require("trix")
require("@rails/activestorage").start()
require("@rails/actiontext")
require("channels")
require("jquery");

import jquery from 'jquery';
import breakpoints from '../src/javascripts/breakpoints.min.js';
import browser from '../src/javascripts/browser.min.js';
import Rails from '@rails/ujs';

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "controllers"

window.Rails = Rails;
window.jquery = jquery;
window.$ = jquery;
window.jQuery = jquery;
window.breakpoints = breakpoints;
