import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "name", "email", "phone", "message" ]

  initialize() {

  }

  connect() {
  }

  checkForm() {
    if(this.emailTarget.value.trim().length > 3 && this.emailTarget.value.trim().includes('@')) {
      this.phoneTarget.required = false;
    } else {
      this.phoneTarget.required = true;
    }

    if(this.phoneTarget.value.trim().length > 3) {
      this.emailTarget.required = false;
    } else {
      this.emailTarget.required = true;
    }
  }
}
